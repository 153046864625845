
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    RigSchedule: () => getComponent('schedule/RigSchedule'),
    ComponentDetails: () => getComponent('schedule/ComponentDetails'),
    AddSchedule: () => getComponent('schedule/AddSchedule'),
    Multiselect,
  },
})
export default class AddJob extends Vue {
  selectedColumnType = 'DETAILS'

  userSelectionVal = ''

  yourValue = ''

  refreshResponseDataKey = 0

  addJobChecklistPopup = false

  addJobIsLoading = false

  addJobGroupSelected = ''

  dataLoading = false

  popupBackground = false

  editGroupPopupInnerShowing = false

  checklistTemporaryText = ''

  addJobResponseData = {}

  showCreationError = ''

  templateDetails = [
    {
      type: 'Fields',
      values: [

      ],
    }, {
      type: 'Rules',
      values: [

      ],
    },
  ]

  get activeComponent() {
  return scheduleModule.activeComponent;
  }

  get activeColumns() {
  return scheduleModule.activeComponentResponseColumns;
  }

  didSelectUserFromDropdown(customId) {
    // @ts-ignore
    this.addJobResponseData[customId].Response = this.userSelectionVal.value;
  }

  selectAddViewOption(columns) {
  const fieldValues: any[] = [];
  fieldValues.push({
    title: 'Add new field',
    icon_type: 'ADD',
    columnType: '',
  });
  for (let x = 0; x < columns.length; x++) {
    fieldValues.push({
      title: columns[x].ColumnName,
      icon: this.getColumnTypeIcon(columns[x].ColumnType),
      icon_type: columns[x].ColumnType === 'WELL' ? 'WELL' : columns[x].ColumnType === 'RIG' ? 'RIG' : 'MATERIAL',
      columnType: columns[x].ColumnType,
      custom_name: columns[x].ColumnName,
    });
  }
    this.templateDetails = [
      {
        type: 'Fields',
        // @ts-ignore
        values: fieldValues,
      },
    ];
  }

  addJobRefreshTable() {
    this.refreshResponseDataKey += 1;
  }

  // NEEDED
  addJobDidSelectGroup() {
    this.addJobSetupPage();
  }

  addJobRemoveChecklistItem(customID, checklistIndex) {
    this.addJobResponseData[customID].Response.splice(checklistIndex, 1);
    this.refreshResponseDataKey += 1;
  }

  addJobSetupPage() {
    const columns = this.addJobColumns();

    // @ts-ignore
    for (let x = 0; x < columns.length; x++) {
      // @ts-ignore
      if (columns[x].ColumnType === 'WELL') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'USER') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'VENDOR') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: {
            PhoneNumber: '',
            Email: '',
            FullName: '',
          },
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'CHECKLIST') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'FILE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: null,
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'TESTERS') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'TASQ_DATE_RANGE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'COST') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'TAGS') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: [],
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'TEXT') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'PROCEDURE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: null,
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'EMAIL') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'PHONE_NUMBER') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'START_DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'DUE_DATE') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      // @ts-ignore
      } else if (columns[x].ColumnType === 'STATUS') {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      } else {
        // @ts-ignore
        this.addJobResponseData[columns[x].CustomID] = {
          // @ts-ignore
          ...columns[x],
          Response: '',
        };
      }
    }
    this.refreshResponseDataKey += 1;
  }

  addNewJobHideClicked() {
    this.$emit('hide-add-new-job-clicked');
  }

  addJobDidEnterChecklistItem(customID, e) {
  if ((e.key === 'Enter' || e.keyCode === 13)
    && this.checklistTemporaryText !== ''
    && this.checklistTemporaryText !== ' ') {
    const newChecklist = {
      // @ts-ignore
      value: this.checklistTemporaryText,
      checked: false,
    };
      // Do something
      this.addJobResponseData[customID].Response = [newChecklist].concat(this.addJobResponseData[customID].Response);
      // @ts-ignore
      this.checklistTemporaryText = '';
  }
  }

  addJobShowChecklistPopup() {
    this.addJobChecklistPopup = true;
  }

  addJobHideChecklistPopup() {
    this.addJobChecklistPopup = false;
  }

  getColumnTypeIcon(columnType) {
    if (columnType === 'WELL') {
      return 'task';
    } if (columnType === 'USER') {
      return 'person';
    } if (columnType === 'VENDOR') {
      return 'engineering';
    } if (columnType === 'CHECKLIST') {
      return 'checklist';
    } if (columnType === 'FILE') {
      return 'picture_as_pdf';
    } if (columnType === 'TESTERS') {
      return 'quiz';
    } if (columnType === 'TASQ_DATE_RANGE') {
      return 'date_range';
    } if (columnType === 'COST') {
      return 'attach_money';
    } if (columnType === 'TAGS') {
      return 'tag';
    } if (columnType === 'TEXT') {
      return 'format_size';
    } if (columnType === 'DATE') {
      return 'event';
    } if (columnType === 'PROCEDURE') {
      return 'task';
    } if (columnType === 'EMAIL') {
      return 'email';
    } if (columnType === 'PHONE_NUMBER') {
      return 'phone';
    } if (columnType === 'STATUS') {
      return 'task_alt';
    }
      return 'task';
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    }));
  }

  addJobColumns() {
    if (this.addJobGroupSelected === '' || this.addJobGroupSelected === null) {
      return;
    }
    let groupSelected;
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      if (workspaceModule.activeTable.Groups[x].GroupName === this.addJobGroupSelected) {
        // @ts-ignore
        groupSelected = workspaceModule.activeTable.Groups[x];
      }
    }
    const columns = groupSelected.Columns;
    return columns;
  }

  get groupNames() {
    const results = [];
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      results.push(workspaceModule.activeTable.Groups[x].GroupName);
    }
    return results;
  }

  async addJob() {
    // @ts-ignore
    this.addJobIsLoading = true;
    let groupSelected = null;
    // @ts-ignore
    for (let x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
      // @ts-ignore
      if (workspaceModule.activeTable.Groups[x].GroupName === this.addJobGroupSelected) {
        // @ts-ignore
        groupSelected = workspaceModule.activeTable.Groups[x];
      }
    }

    let errorMsg = '';

    let startDate = null;
    let dueDate = null;
    const columns: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(this.addJobResponseData)) {
      // @ts-ignore
      if (value.RequiredOnJobCreation) {
        // @ts-ignore
        if (value.Response === '' || value.Response === null
          // @ts-ignore
          || value.Response === undefined || value.Response === []
          // @ts-ignore
          || value.Response === {}) {
          if (errorMsg !== '') {
            // @ts-ignore
            errorMsg += `, "${value.CustomName}"`;
          } else {
            // @ts-ignore
            errorMsg += `"${value.CustomName}"`;
          }
                }
        // @ts-ignore
        if (value.ColumnType === 'START_DATE') {
          // @ts-ignore
          startDate = value.Response;
        }
        // @ts-ignore
        if (value.ColumnType === 'DUE_DATE') {
          // @ts-ignore
          dueDate = value.Response;
        }
            }

      // @ts-ignore
      if (value.ColumnType === 'WELL') {
        // @ts-ignore
        value.Response = value.Response.wellName;
      }
      columns.push(value);
    }

    if (errorMsg !== '') {
      errorMsg = `Error: ${errorMsg} fields are required.`;
      this.addJobIsLoading = false;
      this.showCreationError = errorMsg;
      return;
    }

    // @ts-ignore
    const startDateObj = new Date(startDate);
    // @ts-ignore
    const dueDateObj = new Date(dueDate);
    if (dueDateObj.getTime() <= startDateObj.getTime()) {
      errorMsg = 'Error: Start date must be greater than the end date.';
      this.addJobIsLoading = false;
      this.showCreationError = errorMsg;
      return;
    }

    await scheduleModule.postNewGroupItem({
      username: accountModule.user.email.toLowerCase(),
      // @ts-ignore
      workspace_id: workspaceModule.activeWorkspace.ID,
      // @ts-ignore
      table_id: workspaceModule.activeTable.ID,
      // @ts-ignore
      group_id: groupSelected.ID,
      columns: JSON.stringify(columns),
    });
    // @ts-ignore
    this.addJobIsLoading = false;
    this.addNewJobHideClicked();
    window.location.reload();
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }
}

